import React from "react"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PaddedContent from "../components/paddedContent"
import { redirect } from "variables.js"
const NotFoundPage = () => {
  let pathname = redirect();
  if(pathname){
    navigate(pathname, { replace: true });
  }
 return (
  <Layout>
    <SEO title="404: Not found" />
    <div className="9w-content-not-found"
      style={{
        padding: "200px 0",
        backgroundColor: "#00b2a9",
        color: "white",
      }}
    >
      <PaddedContent>
        <h1>NOT FOUND</h1>
        <p style={{ marginBottom: "20px" }}>
          You just hit a route that doesn&#39;t exist.
        </p>
        <Link to="/" style={{ textDecoration: "underline" }}>
          Go to Home
        </Link>
      </PaddedContent>
    </div>
  </Layout>
)}

export default NotFoundPage
